import axios from 'axios'

const hostApi ='http://backendmedic.ru/';

export default {
  actions: {
     AuthMedics({commit},dataAccess){
      
      axios.post(hostApi+'api/authmedics',{dataAccess}).then((response) => { const data = response.data;
      console.log(data.data[0].name_medics);
      commit('UpdateRulesMedics',data.data[0].rules);     
      commit('UpdateNameMedics',data.data[0].name_medics);   
    }).catch((error) => {
      if(error.response ){
          console.log(error.response.data); // => the response payload
      }
  }); 
     },
     setDataTabelsActions({commit,state},dataAccess){
      if(dataAccess.tabelnamedoctorsman!=''){
        state.tabelnameDoctors=dataAccess.tabelnamedoctorsman;
      }
      axios.post(hostApi+'api/getdatatables',{dataAccess}).then((response) => { const data = response.data.data;
        const data_2 = response.data.data_2;
        const data_3 = response.data.data_3;
        const data_4 = response.data.data_4;
        
        commit('UpdateColumnTabels',data_3);
        commit('UpdateColumnTabels_2',data_4);

        if(data==undefined){
        commit('UpdateDataTabels',[]);
        }
        if(data!=undefined){
          commit('UpdateDataTabels',data);
        }
        if(data_2==undefined){
          commit('UpdateDataTabels_2',[]);
        }
        if(data_2!=undefined){
          commit('UpdateDataTabels_2',data_2);
        }
      
      }).catch((error) => {
        
          if(error.response ){
            console.log(error.response.data); // => the response payload
        }
    });
     },


     setDateTabelsActions({commit},dataAccess){
      axios.post(hostApi+'api/getdatatables',{dataAccess}).then((response) => { const data = response.data.data;    
        const data_3 = response.data.data_3;
        commit('UpdateColumnDate',data_3);
        if(data==undefined){
        commit('UpdateDate',[]);
        }
        if(data!=undefined){
          commit('UpdateDate',data);
        }

      
      }).catch((error) => {
        
          if(error.response ){
            console.log(error.response.data); // => the response payload
        }
    });
     },

     CreateDataTabelActions({commit},dataAccess){
      axios.post(hostApi+'api/createandupdatedata',{dataAccess}).then((response) => {
        console.log(response)
        commit('AllCommit',response); 
      }).catch((error) => {
        console.log(error);
          if(error.response ){
            commit('AllCommit',error.response.data);  // => the response payload
        }

    });
    

     },




     getHospitalAndPointsActions({commit,state},dataAccess){
      if(dataAccess.id_devisions!=''){
        state.id_devisions_state=dataAccess.id_devisions;
      }
      if(dataAccess.id_hospitals!=''){
        state.id_hospitals_state=dataAccess.id_hospitals;
      }
      if(dataAccess.id_points!=''){
        state.id_points_state=dataAccess.id_points;
      }
      if(dataAccess.nameDoctors!=''){
        state.DocName=dataAccess.nameDoctors;
      }
      if(dataAccess.tabelname!=''){
        state.tablename=dataAccess.tabelname;
      }
      if(dataAccess.tabelname_doc!=''){
        state.tabelnameDoctors=dataAccess.tabelname_doc;
      }
      if(dataAccess.NameHospitals_2!=''){
        state.NameHospitals=dataAccess.NameHospitals_2;
      }
      if(dataAccess.NameDivisons_2!=''){
        state.NameDivisions=dataAccess.NameDivisons_2;
      }
        state.CheckTabel=dataAccess.CheckTabel;
  
      axios.post(hostApi+'api/gethospitalsandpoints',{dataAccess}).then((response) => { const data = response.data;
        if(data.data==undefined){
          commit('UpdateHospitalsAndPoints',[]); 
        }
        else{
          commit('UpdateHospitalsAndPoints',data.data); 
        }
    }).catch((error) => {
      if(error.response ){
          console.log(error.response.data); // => the response payload
      }
  });
     },
     ClearData({state}){
      state.Divisions=[];
      state.HospitalAndPoints=[];
      state.tablename='divisions';
      state.id_divisions_state='';
      state.id_hospitals_state='';
      state.id_points_state='';
      state.DocName='';
      state.NameDivisions='';
      state.NameHospitals='';
      state.CheckTabel='false';
      state.tabelnameDoctors='';
      state.dataTabels=[];
      state.dataTabels_2=[];
      state.dataColumn=[];
      state.dataColumn2=[];
     },
     DownloadDevisions({commit}){
      axios.post(hostApi+'api/getdivisions').then((response) => { const data = response.data;
        commit('UpdateDivisions',data);        
    }).catch((error) => {
      if(error.response ){
          console.log(error.response.data); // => the response payload
      }
  }); 
     }
},
  mutations: {
    ExitChange(state,data){
        state.Pages_2=data;
    },
    AllCommit(state,data){
      state.error=data;
  },
    UpdateRulesMedics(state,data){
        state.RulesMedics=data
    },
    UpdateDivisions(state,data){
      state.Divisions=data;
    },
    UpdateHospitalsAndPoints(state,data){
      state.HospitalAndPoints=data;
    },
    UpdateNameMedics(state,data){
      state.Name_medics=data;
    },
    UpdateDataTabels(state,data){
      state.dataTabels=data;  
    },
    UpdateDataTabels_2(state,data){
      state.dataTabels_2=data;  
    },
    UpdateColumnTabels(state,data){
      state.dataColumn=data;  
    },
    UpdateColumnTabels_2(state,data){
      state.dataColumn_2=data;  
    },
    UpdateDate(state,data){
      state.date=data;  
    },
    UpdateColumnDate(state,data){
      state.datecolumn=data;  
    }
  },
  state: {
    RulesMedics: "",
    Divisions:[],
    Name_medics:'',
    HospitalAndPoints:[],
    tablename:'divisions',
    id_divisions_state:'',
    id_hospitals_state:'',
    id_points_state:'',
    DocName:'',
    tabelnameDoctors:'',
    NameHospitals:'',
    NameDivisions:'',
    CheckTabel:'false',
    dataTabels:[],
    dataTabels_2:[],
    error:[],
    dataColumn:[],
    dataColumn_2:[],
    date:'',
    datecolumn:[]
  },
  getters: {
    getDate(state) {
      return state.date;
    },
    getAuthMedics(state) {
      return state.RulesMedics;
    },
    getDevisions(state){
      return state.Divisions;
    },
    getNameMedics(state){
      return state.Name_medics;
    },
    getHospitalAndPoints(state){
      return state.HospitalAndPoints;
    },
    getIdDivisons(state){
      return state.id_divisions_state;
    },
    
    getIdHospitals(state){
      return state.id_hospitals_state;
    },
    getColumnDate(state){
      return state.datecolumn;
    },
    getIdPoints(state){
      return state.id_points_state;
    },
    
    getIdDocName(state){
      return state.DocName;
    },
    getTabelName(state){
      return state.tablename;
    },
    getElements(state){
      return state;
    },
    getTabelNameDoctors(state){
      return state.tabelnameDoctors;
    },
    getNameHospitals(state){
      return state.NameHospitals;
    },
    getNameDivisions(state){
      return state.NameDivisions;
    },
    getCheckTabel(state){
      return state.CheckTabel;
    },
    getDataTabels(state){
      return state.dataTabels;
    },
    getDataTabels_2(state){
      return state.dataTabels_2;
    },
  getallStaTE(state){
    return state;
  },
  getColumn(state){
    return state.dataColumn;
  },
  getColumn_2(state){
    return state.dataColumn_2;
  },
  GetError(state){
    return state.error;
  }

  }
}
