import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'LoginDoctor',
    component: () => import(/* webpackChunkName: "about" */ '../PagesMedics/PagesLogin/LoginDoctor.vue')
  },
   {path: '/AdminPanel',
    name: 'adminpanel',
    component:() => import(/* webpackChunkName: "about" */ '../PagesMedics/PagesAdmin/AdminDoctor.vue') ,
      children: [

    //{ path: '',name:'main', component: () => import(/* webpackChunkName: "about" */ '../PagesMedics/PagesAdmin/ComponentTwoButton/Main') },
    { path: '', name:'main',
    component: () => import(/* webpackChunkName: "about" */ '../PagesMedics/PagesAdmin/ComponentsOneButton/UpdateFile.vue'),
    children: [
      { path: '',name:'list', component: () => import(/* webpackChunkName: "about" */ '../PagesMedics/PagesAdmin/ComponentsOneButton/ListComponents/ListComponents.vue') },
      { path: 'table', name:'table',component: () => import(/* webpackChunkName: "about" */ '../PagesMedics/PagesAdmin/ComponentTabels/Tables.vue')},
    ]},
  ],
  },
  {
    path: '/DoctorUi',
    name: 'DoctorUi',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../PagesMedics/PagesDoctor/Doctor.vue'),
    children: [

     // { path: '',name:'main_2', component: () => import(/* webpackChunkName: "about" */ '../PagesMedics/PagesDoctor/ComponentTwoButton/Main') },
      { path: '', name:'main_2',
      component: () => import(/* webpackChunkName: "about" */ '../PagesMedics/PagesDoctor/ComponentsOneButton/UpdateFile.vue'),
      children: [
        { path: '',name:'list_2', component: () => import(/* webpackChunkName: "about" */ '../PagesMedics/PagesDoctor/ComponentsOneButton/ListComponents/ListComponents.vue') },
        { path: 'table_2', name:'table_2',component: () => import(/* webpackChunkName: "about" */ '../PagesMedics/PagesDoctor/ComponentTabels/Tables.vue')},
      ]},
    ],
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
