import axios from 'axios'

export default {
  actions: {
    ChangePageProfile({commit}){
      commit('UpdatePagesProfile','Profile');
      commit('UpdateRules','Ordered');
      
    }
    ,
    SiteOne({commit}){
      const data='SiteOne';
      commit('UpdateSitePages',data);
    },
    SiteTwo({commit}){
      const data='SiteTwo';
      commit('UpdateSitePages',data);
    },
    SiteVariant({commit}){
      const data='SiteVariant';
      commit('UpdateSitePages',data);
    },
    PostMailCode({commit},email){
     /* const headers = {
        'Content-Type': 'application/json',
      }*/
      axios.post('http://127.0.0.1:8000/api/sendmail',{email}).then((response) => { const data = response.data;
      
      commit('UpdateEmailCode',data)
    }).catch((error) => {
      if( error.response ){
          console.log(error.response.data); // => the response payload
      }
  }); 
    },
    ReqFunction({commit},dataAccess){
      /* const headers = {
         'Content-Type': 'application/json',
       }*/
       axios.post('http://127.0.0.1:8000/api/register',{dataAccess}).then((response) => { const data = response.data;
       commit('UpdateCheckReq',data)
     }).catch((error) => {
       if(error.response ){
           console.log(error.response.data); // => the response payload
       }
   }); 
     },
     Auth({commit},dataAccess){
      axios.post('http://127.0.0.1:8000/api/auth',{dataAccess}).then((response) => { const data = response.data;
        commit('UpdateCheckAuth',data.check);
        commit('UpdateDataUser',data.data);
        
    }).catch((error) => {
      if(error.response ){
          console.log(error.response.data); // => the response payload
      }
  }); 
     },
     ExitChangeFile({commit}){
      commit('ExitChange','SiteOne');
     }
  },
  mutations: {
    ExitChange(state,data){
        state.Pages_2=data;
        state.pages='';
        state.rules='';    
        state.authSiteOne='false'
    },
    UpdatePagesProfile(state,data){
      state.pages=data;
    },
    UpdateRules(state,data){
      state.rules=data;
    },
    UpdateEmailCode(state,emailcode){
       state.emailCode=emailcode;
    },
    UpdateSitePages(state,SitePages_1){
      state.Pages_2=SitePages_1;
    },
    UpdateDataPost(state,DataPost){
      state.user = DataPost;
    },
    UpdateCheckReq(state,DataPost){
      state.checkReqister = DataPost;
    },
    UpdateCheckAuth(state,DataPost){
      console.log(DataPost);
      state.authSiteOne = DataPost;
    },
    UpdateDataUser(state,DataPost){
      console.log(DataPost);
      state.user = DataPost;
    }
  },
  state: {
    orders: [],
    user:{},
    user_id:'',
    checkReqister:'',
    rules:'Ordered',
    pages:'Profile',
    Pages_2:'SiteVariant',
    emailCode:'',
    authSiteOne:'false',
    authSiteTwo:'false',
    loaddata:true,
    orderOne:[],
    freelance_dol:'false'
  },
  getters: {
    getEmailCode(state) {
      return state.emailCode;
    },
    addPages(state){
      return state.Pages_2;
    },
    getCheckVhod(state){
      return state.checkvhod
    },
    getPages(state){
      return state.pages
    },
    getRules(state){
      return state.rules
    },
    getAuthSiteOne(state){
      return state.authSiteOne;
    },
    getAuthSiteTwo(state){
      return state.authSiteTwo;
    },
    getFindUser(state){
      return state.user;
    },
    getLoadataProfile(state){
      return state.loaddata;
    },
    getFreelanceGol(state){
      return state.freelance_dol;
    },
  }
}
