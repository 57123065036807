import post_2 from './modules/post';
import medics from './modules/medicsdata';
import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate"


const store = createStore({
  modules: {
    post:post_2,
    medics
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage,
})],
});

export default store;
