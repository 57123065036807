<template>
  <transition name="router-anim" enter-active-class="animated fadeInRight" leave-active-class="animated fadeOutLeft">
  <router-view/>
  </transition>
</template>

<style lang="scss">
@import "https://cdn.jsdelivr.net/npm/animate.css@3.5.1"; 
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.page{
width:inherit;
}
</style>
