import { createApp } from 'vue'
import App from './App.vue'
import store from '/src/store/index'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router/index'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "bootstrap-icons/font/bootstrap-icons.css"




const app = createApp(App).use(router)

app.use(router);
app.use(store)
app.use(VueAxios, axios)
app.mount('#app')

